import { makeStyles } from '@material-ui/core/styles';

const Style = makeStyles((theme) => ({
    footerRoot: {
        height: 90,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        maxWidth: theme.sizes.pageWidth,
    },
    footerBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        height: '100%',
        paddingLeft: theme.sizes.generalPadding,
        paddingRight: theme.sizes.generalPadding,
    },
    footerLogoBox: {
        flexDirection: "column",
        justifyContent: "center",
        display: 'flex',
        height: 90
    },
    footerLogoText: {
        fontSize: 30,
        fontFamily: theme.fonts.primary.family,
        fontWeight: theme.fonts.primary.weight,
        letterSpacing: -1,
        transform: 'scale(1, 1.1)',
        cursor: 'pointer'
    },
    socialIconsBox: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: "flex-start",
        alignItems: 'center',
        display: 'flex',
        height: 90,
        paddingLeft: 32
    },
    socialIcon: {
        fontSize: 24,
        cursor: 'pointer',
        opacity: 0.7,
        marginRight: 16,
        '&:hover': {
            opacity: 1,
        }
    },
    socialIconLink: {
        height: 24
    },
    arrowUp: {
        fontSize: 30,
        opacity: 0.6,
        cursor:'pointer',
        '&:hover': {
            opacity: 1,
        },
        marginRight: -4
    },
    footerPartnerContentMobile: {
        display: 'flex',
        flexDirection: "row",
        alignItems: 'center',
        fontSize: 18,
        width: '40%',
        fontFamily: theme.fonts.primary.family,
        fontWeight: theme.fonts.primary.weight,
    },
    footerPartner: {
        display: 'flex',
        flexDirection: "row",
        alignItems: 'center',
        fontSize: 17,
        height: 90,
        fontFamily: theme.fonts.primary.family,
        fontWeight: theme.fonts.primary.weight,
    },
    footerPartnerMobile: {
        display: 'flex',
        width: '100%',
        position: 'relative'
    },
    partnerImg: {
        height: 50,
        marginRight: 0,
        cursor: 'pointer',
        [theme.breakpoints.up("sm")]: {
            marginRight: 24,
        },
    },
    partnerImgLogo: {
        marginRight: 24,
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 70,
        height: 70,
    },
    partnerImgLogoMobile: {
        marginRight: 15,
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 40,
        height: 40,
    },
    partnerImgBoxMobile: {
        position: 'absolute',
        right: 0,
        top: 20
    },
    mobileArrowUp: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    footerBoxMobile: {
        width: '100%',
        height: 60,
        backgroundColor: '#eee',
        display: 'flex',
        flex: 1,
        paddingLeft: theme.sizes.generalPadding
    },
    footerLogoBoxMobile: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "flex-start"
    },
    socialIconsBoxMobile: {
        flex: 1,
        justifyContent: "flex-end",
        alignItems: 'center',
        display: 'flex',
        paddingLeft: 32,
        paddingRight: theme.sizes.generalPadding,
    },
    footerLogoTextMobile: {
        fontSize: 18,
        fontFamily: theme.fonts.primary.family,
        fontWeight: theme.fonts.primary.weight,
        letterSpacing: -1,
        transform: 'scale(1, 1.1)',
        cursor: 'pointer',
        margin: 0,
        height: 60,
        display: 'flex',
        alignItems: 'center'
    }

}));

export default Style;